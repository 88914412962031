<template>
  <div>
    <div class="mb-3">
      <h1 :class="$style.pageTitle" style="display:inline-block;">会社グループ一覧</h1>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="companyGroupLoading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!companyGroupLoading">
              <p class="text-right">
                <router-link :to="{ name: 'companyGroupCreate' }">
                  <a-button type="primary" class="ml-3" autoInsertSpaceInButton="false" :class="$style.filledBtn">作成</a-button>
                </router-link>
              </p>
              <a-table
                :columns="columns"
                :data-source="companyGroupData"
                :pagination="true"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 :  $style.stripe1"
              >
                <span slot="name" slot-scope="name, record"><router-link :to="{ name: 'companyGroupShow', params: { id: record.id }}">{{ name }}</router-link></span>
                <span slot="comment" slot-scope="comment">{{ comment }}</span>
                <span slot="companies_count" slot-scope="companies_count">{{ companies_count }}</span>
                <span slot="actions" slot-scope="item, record">
                  <router-link :to="{ name: 'companyGroupUpdate', params: { id: record.id, companyGroup: record }}">
                    <a-button type="default" shape="round" icon="edit" style="margin-left: 10px;">編集</a-button>
                  </router-link>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  data() {
    return {
      companyGroupData: [],
      companyGroupLoading: true,
      columns: [
        {
          title: '名前',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'コメント',
          dataIndex: 'comment',
          scopedSlots: { customRender: 'comment' },
        },
        {
          title: '所属会社数',
          dataIndex: 'companies_count',
          scopedSlots: { customRender: 'companies_count' },
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ],
    }
  },
  created() {
    const companyGroupList = Vue.prototype.$api.send('get', 'company_groups')
    companyGroupList.then(response => {
      this.companyGroupLoading = false
      this.companyGroupData = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社グループの取得に失敗しました。',
        })
        this.companyGroupLoading = false
      })
  },
  methods: {
    handleChange(selectedPlans) { this.selectedPlans = selectedPlans },
    changeSelectedCompanyName(vals) { this.selectedCompanies = vals },
    changeSelectedCompanyId(vals) { this.selectedCompanieIds = vals },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.filterHeader {
  font: normal normal bold 14px/33px $v2-font-family;
  letter-spacing: 0px;
}
.list-name {
  font-size: 1.5rem;
}
</style>
