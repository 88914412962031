var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-3" }, [
      _c(
        "h1",
        {
          class: _vm.$style.pageTitle,
          staticStyle: { display: "inline-block" }
        },
        [_vm._v("会社グループ一覧")]
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.companyGroupLoading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.companyGroupLoading
              ? _c(
                  "div",
                  [
                    _c(
                      "p",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "companyGroupCreate" } } },
                          [
                            _c(
                              "a-button",
                              {
                                staticClass: "ml-3",
                                class: _vm.$style.filledBtn,
                                attrs: {
                                  type: "primary",
                                  autoInsertSpaceInButton: "false"
                                }
                              },
                              [_vm._v("作成")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.companyGroupData,
                        pagination: true,
                        rowKey: "id",
                        rowClassName: function(r, i) {
                          return i % 2 === 0
                            ? _vm.$style.stripe0
                            : _vm.$style.stripe1
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function(name, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "companyGroupShow",
                                          params: { id: record.id }
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(name))]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "comment",
                            fn: function(comment) {
                              return _c("span", {}, [_vm._v(_vm._s(comment))])
                            }
                          },
                          {
                            key: "companies_count",
                            fn: function(companies_count) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(companies_count))
                              ])
                            }
                          },
                          {
                            key: "actions",
                            fn: function(item, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "companyGroupUpdate",
                                          params: {
                                            id: record.id,
                                            companyGroup: record
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px"
                                          },
                                          attrs: {
                                            type: "default",
                                            shape: "round",
                                            icon: "edit"
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        1557703707
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }